import React from 'react'
import styles from './Footer.module.scss'

const Footer = ({ onSubmit, onCancel }) => {
  return (
    <div className={`${styles.footer} d-flex justify-content-end align-items-center`}>
        <button
          type="button"
          className="outline-button"
          onClick={onCancel}
        >
          Cancel
        </button>
        <div className={styles.footer_save}>
          <button
            type="submit"
            className="outline-blue-button"
            disabled={false}
            onClick={() => {
              onSubmit('Draft');
            }}
          >
            Save as Draft
          </button>
          <button
            type="submit"
            className="regular-button"
            disabled={false}
            onClick={() => {
              onSubmit('Active');
            }}
          >
            Publish Campaign
          </button>
      </div>
    </div>
  )
}

export default Footer
