import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getTimesDisplay, 
  setTimesDisplay,
  getTimeBetweenDisplay,
  setTimeBetweenDisplay,
  getHideAfterSubmission,
  setHideAfterSubmission,
  getHideIfButtonClicked,
  setHideIfButtonClickedChecked,
  setHideIfButtonClickedButton,
  getCloseIfButtonClicked,
  setCloseIfButtonClickedChecked,
  setCloseIfButtonClickedButton,
  getForm,
} from '../../../store/reducers/campaign';
import { formsApi } from '../../../api/forms';
import TextWithValue from '../Components/TextWithValue';
import TextWithToggle from '../Components/TextWithToggle';
import TextWithDropdownAndToggle from '../Components/TextWithDropdownAndToggle';
import styles from './AdvancedSettings.module.scss';
import { STATIC_FIELDS } from '../../Forms/Constants';
import { parseButtons } from '../helper';

const AdvancedSettings = () => {
  const dispatch = useDispatch();
  const [showContent, setShowContent]= useState(false);
  const [buttons, setButtons] = useState([]);
  const form = useSelector(getForm);
  const timesDisplay = useSelector(getTimesDisplay);
  const timeBetweenDisplay = useSelector(getTimeBetweenDisplay);
  const hideAfterSubmission = useSelector(getHideAfterSubmission);
  const hideIfButtonClicked = useSelector(getHideIfButtonClicked);
  const closeIfButtonClicked = useSelector(getCloseIfButtonClicked);

  const handleChange = (action, value) => {
    dispatch(action(value));
  }

  const handleChangeTyped = (action, type, value) => {
    switch (action) {
      case 'hide':
        switch (type) {
          case 'click':
            dispatch(setHideIfButtonClickedChecked(value));
            break;
            case 'button':
            dispatch(setHideIfButtonClickedButton(value));
            break;
        }
        break;
      case 'close':
        switch (type) {
          case 'click':
            dispatch(setCloseIfButtonClickedChecked(value));
            break;
          case 'button':
            dispatch(setCloseIfButtonClickedButton(value));
            break;
        }
        break;
    }
  }

  const {
    currentData: formData,
    isLoading: formLoading,
    isFetching: formFetching,
  } = formsApi.useGetFormByIdQuery(
    {
      id: form?.id,
    },
    { skip: !form },
  );

  const formDataLoading = formLoading || formFetching;

  const isStaticForm = (formData?.field_settings?.find((page) => page.pageName === 'main-form')?.fields.every(
    (field) => STATIC_FIELDS.includes(field.name.toLowerCase()),
  )) || false;

  useEffect(() => {
    if (formData) {
      const buttonList = parseButtons(formData?.field_settings?.find((page) => page.pageName === 'main-form')?.fields.filter((f) => f.type === 'button'));
      setButtons(buttonList);
      if (buttonList.length > 0) {
        if (!hideIfButtonClicked?.button) {
          dispatch(setHideIfButtonClickedButton(buttonList[0]));
        }
        if (!closeIfButtonClicked?.button) {
          dispatch(setCloseIfButtonClickedButton(buttonList[0]));
        }
      }
    } else {
      setButtons([]);
    }
  }, [formData])

  return (
    <>
      <button 
        type='button' 
        className={`${styles.button} ${showContent ? styles.opened : ''}`} 
        onClick={() => setShowContent(!showContent)}
      >
        Advanced settings {showContent ? '-' : '+'}
      </button>
      {showContent && (
        <div className={styles.content}>
          <TextWithValue 
            textBefore="Display the form up to" 
            value={timesDisplay} 
            textAfter="times" 
            onChange={(newValue) => handleChange(setTimesDisplay, Number(newValue))}
          />
          <TextWithValue 
            textBefore="Minimum time between displays" 
            value={timeBetweenDisplay} 
            textAfter="days" 
            onChange={(newValue) => handleChange(setTimeBetweenDisplay, Number(newValue))}
          />
          { form && !formDataLoading && isStaticForm ? (
            <>
              <TextWithDropdownAndToggle 
                textBefore={'Do not show again if button'} 
                textBetween={'is clicked'} 
                isChecked={hideIfButtonClicked?.checked}
                button={hideIfButtonClicked?.button}
                data={buttons}
                onChange={(type, newValue) => handleChangeTyped('hide', type, newValue)}
              />
              <TextWithDropdownAndToggle 
                textBefore={'Close the modal if button'} 
                textBetween={'is clicked'} 
                isChecked={closeIfButtonClicked?.checked}
                button={closeIfButtonClicked?.button}
                data={buttons}
                onChange={(type, newValue) => handleChangeTyped('close', type, newValue)}
              />
            </>) : null
          }
          { form && !formDataLoading && !isStaticForm ? (
              <TextWithToggle 
                text={'Do not show again if submitted'} 
                isChecked={hideAfterSubmission}
                onChange={(newValue) => handleChange('setHideAfterSubmission', newValue)}
              />
            ) : null
          }
        </div>
      )}
    </>
  )
}

export default AdvancedSettings
