import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import TWV from '../../TextWithValue';

const TextWithValue = ({
  conditionId,
  label,
  value,
  textBefore,
  textAfter,
  max = null,
}) => {
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    if (newValue >= 0 && (!max || newValue <= max)) {
      dispatch(setSelectedValue({ conditionId, label, value: newValue }));
    }
  }

  return (
    <TWV 
      conditionId={conditionId}
      label={label}
      value={value}
      textBefore={textBefore}
      textAfter={textAfter}
      onChange={handleChange}
    />
  )
}

export default TextWithValue
