import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import uniqueId from '../../../../../utils/uniqueId';
import Trash from '../../../../../assets/icons/trash.svg';
import DropdownInput from '../DropdownInput';
import AddButton from '../../AddButton';
import { getDefaultForCondition } from '../../../options';
import styles from './DropdownInputList.module.scss';

const DropdownInputList = ({ conditionId, label, value, data }) => {
  const dispatch = useDispatch();
  const defaultRow = useMemo(() => {
    const defaultValue = getDefaultForCondition(conditionId, label);
    if (defaultValue && defaultValue.length > 0) {
      return defaultValue[0];
    } else {
      return null;
    }
  }, [conditionId, label]);
  
  const handleAddRow = () => {
    dispatch(setSelectedValue({ conditionId, label, value: [...value, defaultRow] }))
  }

  const handleDelete = (index) => {
    dispatch(setSelectedValue({ conditionId, label, value: value.filter((_, i) => i !== index) }))
  }

  return (
    <div className={styles.wrapper}>
      {value.map((row, index) => (
        <div key={`${conditionId}${label}${index}`} className={styles.row}>
          <DropdownInput 
            conditionId={conditionId}
            label={label}
            index={index}
            condition={row.condition} 
            conditionData={data} 
            text={row.text} 
            allValues={value}
          />
          {value.length !== 1 && 
            <button className={styles.trash} type='button' onClick={() => handleDelete(index)}><Trash /></button>}
          {index === value.length - 1 && 
            <AddButton onClick={handleAddRow} />}
        </div>
      ))}
    </div>
  )
}

export default DropdownInputList
