import React, { useState, useEffect } from 'react';
import {
  Outlet, useNavigate, useLocation,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary } from "react-error-boundary";
import { useSelector, useDispatch } from 'react-redux';
import LoginModal from '../../ui/modals/LoginModal';
import Header from '../Header';
import Footer from '../Footer';
import './Wrapper.scss';
import { selectPills } from '../../../store/reducers/search';
import { clearState, getState } from '../../../store/reducers/app';
import ForbiddenModal from '../../ui/modals/ForbiddenModal';
import Loader from '../../base/Loader';
import NotFound from '../../../pages/NotFound';
import { getUser } from '../../../store/reducers/user';
import { trackPageView } from '../../../utils/mixpanel/mixpanelEvents';
import { showErrorMessage } from '../../base/Notifications';

const ErrorFallback = ({ error }) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`Error : ${error.message}`);
  } else {
    console.error(`Error : ${error.message}`);
  }

  const state = useSelector(getState);

  if (!state.isError) {
    showErrorMessage('Something went wrong. Please, refresh the page and try again.');
  }

  return null;
}

const Wrapper = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    isAuthenticated, isLoading, logout,
  } = useAuth0();
  const activePills = useSelector(selectPills);
  const state = useSelector(getState);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const onProceed = () => {
    if (location.pathname.startsWith('/form-analytics/')) {
      dispatch(clearState());
    } else if (location.pathname !== '/dashboard') {
      navigate(-1);
      dispatch(clearState());
    } else {
      dispatch(clearState());
      logout();
    }
  };

  useEffect(() => {
    if (import.meta.env.VITE_APP_MIXPANEL_TOKEN && Object.keys(user).length > 0) {
      trackPageView(user, location.pathname);
    }
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoadingUser(false), 1000);
    return () => clearTimeout(timer);
  }, [user.data_export]);

  if (state.isError && state.errorType === 'Not Found') { return <NotFound />; }

  return (
      <div className="wrapper d-flex flex-column" onClick={() => { setShowDropdown(false); }}>
        <header>
          <Header showDropdown={showDropdown} setShowDropdown={setShowDropdown} />
        </header>

        <main className="container-fluid mb-3 main">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {
            isAuthenticated
            || ['/accepted', '/rejected', '/gcp-signup'].some((path) => location.pathname.startsWith(path))
              ? <Outlet />
              : isLoading
                ? (
                  <div className="p-5">
                    <Loader />
                  </div>
                )
                : <LoginModal />
          }
        </ErrorBoundary>
        </main>
        <footer className="mt-auto">
          {activePills.length ? <Footer type="homepage" /> : null}
        </footer>
        {state.isError && state.errorType === 'Unauthorized' && <LoginModal />}
        {state.isError && state.errorType === 'Rejected'
        && <ForbiddenModal onProceed={onProceed} toLogin={location.pathname === '/dashboard'} />}
        {!isLoadingUser && !user.data_export && (location.pathname === '/csv-upload')
        && <ForbiddenModal onProceed={onProceed} toLogin={location.pathname === '/dashboard'} />}
        {!isLoadingUser && !user.forms && (location.pathname === '/add-form')
        && <ForbiddenModal onProceed={onProceed} toLogin={location.pathname === '/dashboard'} />}
      </div>
  );
};

export default Wrapper;
