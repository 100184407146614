import React, {
  useState, useRef, useCallback, useMemo, useEffect,
} from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  MarkerType,
  getConnectedEdges,
  applyNodeChanges,
  applyEdgeChanges,
} from 'react-flow-renderer';
import * as Bootstrap from 'bootstrap';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';

import ToolBox from './ToolBox';
import CustomEdge from './CustomEdge';
import NodeSettings from './NodeSettings';
import {
  clearFlowState, onSettingsChange,
  selectConfigsList, selectErrors,
  selectUserFlowName,
  setErrors,
  setFlowName,
  setFlowStatus,
  selectSendGridApiKey, setSendGridApiKey, setRandomSplitValue, selectXMTPKey, setXMTPKey,
  selectTeamBalanceReducer,
} from '../../../store/reducers/flows';
import { flowsApi } from '../../../api/flows';
import { showErrorMessage } from '../../../components/base/Notifications';
import ErrorsModal from '../../../components/ui/modals/ErrorsModal';
import Loader from '../../../components/base/Loader';
import BlinkingDot from '../../../components/ui/BlinkingDot';
import { useCallbackPrompt } from '../../../components/hooks/app/useCallbackPrompt';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import {
  AirDropNode, ConditionNode, ConnectorNode, DiscordNode, EmailNode, ListNode, PushNode,
  RandomSplitNode, SegmentsNode, SMSNode, TransactionNode, TwitterNode, WaitForNode,
  WaitUntilNode, WebhookESNode, WebhookNode, AddToListNode, XMTPNode,
} from './Nodes';
import styles from './CreateFlow.module.scss';
import EmailModal from './Nodes/EmailNode/EmailModal';
import RoundSpinner from '../../../components/base/RoundSpinner';
import RandomSplitEdge from './RandomSplitEdge';
import StartStopDateFlowModal from '../StartStopDateFlowModal';
import EditableTitle from '../../../components/base/EditableTitle';
import EditableFoldersList from '../../../components/ui/EditableFoldersList';
import { clearState } from '../../../store/reducers/segment';
import { getUser } from '../../../store/reducers/user';
import { trackFlowCreated } from '../../../utils/mixpanel/mixpanelEvents';
import FormNode from './Nodes/FormNode';

const initialNodes = [];
const initialEdges = [];
const initialDeleteKeyCode = [];
const initialSnapGrid = [30, 30];

const CreateFlow = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { flowId } = useParams();
  const user = useSelector(getUser);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [titleValue, setTitleValue] = useState('');
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [previousNodesState, setPreviousNodesState] = useState(nodes);
  const [lastId, setLastId] = useState(0);
  const [showAirDropModal, setShowAirDropModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showErrorsModal, setShowErrorsModal] = useState(false);
  const [activationStop, setActivationStop] = useState(false);
  const [startAndStopDateFlow, setStartAndStopDateFlow] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  const reactFlowWrapper = useRef(null);
  const airDropPermission = useSelector(getUser);
  const configList = useSelector(selectConfigsList);
  const teamBalance = useSelector(selectTeamBalanceReducer);
  const flowName = useSelector(selectUserFlowName);
  const flowErrors = useSelector(selectErrors);
  const isKeyAdded = useSelector(selectSendGridApiKey);
  const XMPTKey = useSelector(selectXMTPKey);

  const {
    isLoading,
    isFetching,
  } = flowsApi.useGetFlowsQuery({ limit: 10 });

  const {
    currentData: flow,
    isLoading: isFlowLoading,
    isFetching: isFlowFetching,
  } = flowsApi.useGetFlowByIdQuery(
    {
      id: flowId,
    },
    { skip: !flowId },
  );

  const {
    currentData: flowStats,
    isLoading: isStatsLoading,
    isFetching: isStatsFetching,
    isError: isStatsError,
  } = flowsApi.useGetFlowStatsQuery(
    {
      id: flowId,
    },
    { skip: !flowId || !flow || flow?.status === 'draft' },
  );

  const mainContainer = document.getElementsByClassName('main');
  mainContainer !== null && mainContainer.length !== 0 ? mainContainer[0].style.padding = 0 : null

  const [saveFlow, result] = flowsApi.useSaveFlowMutation();
  const [changeFlow, updateResult] = flowsApi.useChangeFlowMutation();
  const [stopFlow, stopFlowResult] = flowsApi.useStopFlowMutation();

  const getId = (last) => `node_${last}`;

  const onLabelChange = useCallback((id, source, label) => {
    const tempEdges = reactFlowInstance.getEdges();
    const tempNodes = reactFlowInstance.getNodes();
    tempEdges.forEach((edge) => {
      if (edge.id === id) {
        edge.label = `${label}%`;
        delete edge.selected;
        const nodeIndex = tempNodes.findIndex((object) => object.id === source);
        if (nodeIndex !== -1) {
          tempNodes[nodeIndex].edited = true;
        }
      }
    });
    setEdges(tempEdges);
    setNodes(tempNodes);
  }, [reactFlowInstance, setEdges, setNodes]);

  const unselectEdge = useCallback((id) => {
    const tempEdges = reactFlowInstance.getEdges();
    tempEdges.forEach((edge) => {
      if (edge.id === id) {
        delete edge.selected;
      }
    });
    setEdges(tempEdges);
  }, [reactFlowInstance, setEdges]);

  const onEdgeRemove = useCallback((edgeId) => {
    const tempEdges = reactFlowInstance.getEdges();
    const tempNodes = reactFlowInstance.getNodes();

    const index = tempEdges.findIndex((elem) => elem.id === edgeId);
    if (tempEdges[index].sourceHandle.includes('randomSplitNode_output')) {
      const nodeIndex = tempNodes.findIndex((node) => node.id === tempEdges[index].source);
      if (nodeIndex !== -1 && tempNodes[nodeIndex].edited) {
        if (tempEdges.filter((elem) => elem.source === tempEdges[index].source).length === 1) {
          delete tempNodes[nodeIndex].edited;
          setNodes(tempNodes);
        }
      } else {
        const edgeCount = tempEdges.filter((elem) => elem.source === tempEdges[index].source).length;
        tempEdges.forEach((elem) => {
          if (elem.source === tempEdges[index].source) {
            elem.label = `${(100 / (edgeCount - 1)).toFixed(0)}%`;
          }
        });
      }
    }

    const newArrayOfEdges = tempEdges.filter((elem) => elem.id !== edgeId);
    setEdges(newArrayOfEdges);
    setShowDialog(true);
  }, [reactFlowInstance, setEdges]);

  const onConnect = useCallback((params) => {
    if (flow?.status === 'running' || flow?.status === 'scheduled') {
      return;
    }
    const sameConnection = edges.filter((elem) => (
      params.source === elem.source && params.target === elem.target));
    const isSameNode = params.source === params.target;
    if (isSameNode) return;
    if (sameConnection.length) return;
    const labelText = (elem, count) => `${(100 / count).toFixed(0)}%`;

    setEdges((eds) => {
      if (params.sourceHandle.includes('randomSplitNode_output')) {
        const getNodes = reactFlowInstance.getNodes();
        const isNodeEdited = getNodes.some((node) => node.id === params.source && node.edited);
        if (isNodeEdited) {
          const rest = 100 - eds
            .filter((ed) => ed.source === params.source)
            .reduce((acc, cur) => acc + +cur.label.split('%').shift(), 0);

          return addEdge({
            ...params,
            type: 'random',
            markerEnd: { type: MarkerType.Arrow },
            label: rest >= 0 ? `${rest}%` : '0%',
            data: { onEdgeRemove, onLabelChange, unselectEdge },
          }, eds);
        }

        const randomSplitOutputsCount = eds.filter((elem) => (
          elem.sourceHandle.includes('randomSplitNode_output') && elem.source === params.source
        )).length + 1;

        const newArr = [...eds];
        newArr.forEach((elem) => {
          if (elem.source === params.source) {
            elem.label = labelText(elem, randomSplitOutputsCount);
          }
        });

        return addEdge({
          ...params,
          type: 'random',
          markerEnd: { type: MarkerType.Arrow },
          label: labelText(params, randomSplitOutputsCount),
          data: { onEdgeRemove, onLabelChange, unselectEdge },
        }, newArr);
      }
      if (params.sourceHandle === 'conditionNode_output_b') {
        return addEdge({
          ...params,
          type: 'custom',
          markerEnd: { type: MarkerType.Arrow },
          label: 'Yes',
          data: { onEdgeRemove },
        }, eds);
      }
      if (params.sourceHandle === 'conditionNode_output_c') {
        return addEdge({
          ...params,
          type: 'custom',
          markerEnd: { type: MarkerType.Arrow },
          label: 'No',
          data: { onEdgeRemove },
        }, eds);
      }
      return addEdge({
        ...params,
        type: 'custom',
        markerEnd: { type: MarkerType.Arrow },
        data: { onEdgeRemove },
      }, eds);
    });
    setShowDialog(true);
  }, [edges, onEdgeRemove, setEdges]);

  useEffect(() => {
    if (flow?.status !== 'running'
      && flow?.status !== 'stopped'
      && flow?.status !== 'scheduled'
      && edges
      && edges.some((edge) => edge.sourceHandle.includes('randomSplitNode_output'))) {
      const obj = {};
      edges.forEach((elem) => {
        if (elem.sourceHandle.includes('randomSplitNode_output')) {
          if (obj[elem.source]?.value) {
            obj[elem.source].value += +elem.label.split('%').shift();
          } else {
            obj[elem.source] = {
              value: +elem.label.split('%').shift(),
            };
          }
        }
      });
      dispatch(setRandomSplitValue(obj));
    }
  }, [dispatch, edges]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onNodeDragStop = useCallback(() => {
    setShowDialog(true);
  }, []);

  // const onNodeDragStop = useCallback((event, node) => {
  //   event.preventDefault();
  //   const [entryPoint] = (nodes.filter((elem) => (elem.type === 'entryNode')));
  //   if (entryPoint && node.type !== 'entryNode'
  //   && (node.position.x < entryPoint.position.x + entryPoint.width + 100)) {
  //     const newArr = [...nodes];
  //     const index = newArr.findIndex((elem) => elem.id === node.id);
  //     newArr[index].position.x = entryPoint.position.x + entryPoint.width + 100;
  //     setNodes(newArr);
  //   }
  // }, [nodes, setNodes]);

  const onRemove = useCallback(
    (nodeId) => {
      const tempNodes = reactFlowInstance.getNodes();
      const tempEdges = reactFlowInstance.getEdges();
      const index = tempNodes.findIndex((elem) => elem.id === nodeId);
      const edgesToRemove = getConnectedEdges([tempNodes[index]], tempEdges);
      edgesToRemove.forEach((edge) => {
        if (edge.sourceHandle.includes('randomSplitNode_output')) {
          const nodeIndex = tempNodes.findIndex((node) => node.id === edge.source);
          if (nodeIndex !== -1 && tempNodes[nodeIndex].edited) {
            const lastIndexOf = edgesToRemove.map((elem) => elem.source).lastIndexOf(edge.source);
            if (lastIndexOf === tempEdges.filter((elem) => elem.source === edge.source).length - 1) {
              delete tempNodes[nodeIndex].edited;
            }
          } else {
            const edgeCount = tempEdges.filter((elem) => elem.source === edge.source).length;
            tempEdges.forEach((elem) => {
              if (elem.source === edge.source) {
                elem.label = `${(100 / (edgeCount - 1)).toFixed(0)}%`;
              }
            });
          }
        }
      });

      const edgeChanges = edgesToRemove.map((elem) => ({
        id: elem.id,
        type: 'remove',
      }));

      const nodeChanges = [tempNodes[index]].map((n) => ({ id: n.id, type: 'remove' }));

      const newArrayOfNodes = tempNodes.filter((elem) => elem.id !== nodeId);
      const newArrayOfEdges = tempEdges.filter((elem) => !edgesToRemove.includes(elem));

      setEdges(newArrayOfEdges);
      setNodes(newArrayOfNodes);
      onEdgesChange(edgeChanges);
      onNodesChange(nodeChanges);
      applyNodeChanges([], newArrayOfNodes);
      applyEdgeChanges([], newArrayOfEdges);
      if (newArrayOfNodes.length) {
        setShowDialog(true);
      } else {
        setShowDialog(false);
      }
    },
    [reactFlowInstance, setEdges, setNodes],
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const [entryPoint] = (nodes.filter((elem) => (
        elem.type === 'segmentsNode'
        || elem.type === 'webhookESNode'
        || elem.type === 'listNode'
        || elem.type === 'transactionNode'
        || elem.type === 'formNode'
      )));
      if ((
        type === 'segmentsNode'
        || type === 'webhookESNode'
        || type === 'listNode'
        || type === 'transactionNode'
        || type === 'formNode'
      ) && entryPoint) return;

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top - 30,
      });

      const newNode = {
        id: getId(lastId),
        type,
        position,
        data: { label: `${type}`, onRemove },
      };
      setNodes((nds) => nds.concat(newNode));
      setLastId(lastId + 1);
      setShowDialog(true);
    },
    [lastId, nodes, onRemove, reactFlowInstance, setNodes],
  );

  useEffect(() => {
    if (configList.length && nodes.length === 0 && reactFlowInstance) {
      const centerX = (window.innerWidth / 2) - 200;
      const centerY = (window.innerHeight / 2) - 300;

      const nodesToAdd = [];
      configList.forEach((config, index) => {
        switch (config.name) {
          case 'Airdrop':
            nodesToAdd.push(
              {
                id: getId(lastId + index),
                type: 'airDropNode',
                position: {
                  x: centerX + (configList.length === 1 ? 0 : 100),
                  y: centerY,
                },
                data: {
                  label: 'airDropNode',
                  onRemove,
                },
                width: 380,
                height: 120,
              },
            );
            break;
          case 'EmptyForm':
            nodesToAdd.push(
              {
                id: getId(lastId + index),
                type: 'formNode',
                position: {
                  x: centerX + (configList.length === 1 ? 0 : -200),
                  y: centerY + (configList.length === 1 ? 0 : 60),
                },
                data: { label: 'formNode', onRemove },
              },
            );
            break;
          default:
            if (config.url) {
              nodesToAdd.push(
                {
                  id: getId(lastId + index),
                  type: 'formNode',
                  position: {
                    x: centerX + (configList.length === 1 ? 0 : -200),
                    y: centerY + (configList.length === 1 ? 0 : 60),
                  },
                  data: { label: 'formNode', onRemove },
                },
              );
            }
            break;
        }
      });

      setNodes(nodesToAdd);

      const edgesToAdd = [];
      const nodesId = nodesToAdd.map((node) => node.id);
      for (let index = 0; index < nodesId.length - 1; index += 1) {
        edgesToAdd.push({
          source: nodesId[index],
          sourceHandle: 'listOutput',
          target: nodesId[index + 1],
          targetHandle: null,
          type: 'custom',
          markerEnd: {
            type: 'arrow',
          },
          data: {
            onEdgeRemove,
          },
          id: `reactflow__edge-${nodesId[index]}listOutput-${nodesId[index + 1]}`,
        });
      }

      setEdges(edgesToAdd);

      setLastId(lastId + configList.length);
      setShowDialog(true);
    }
  }, [configList, reactFlowInstance]);

  const onSubmit = (status, dates = {}) => {
    if (!flowName) {
      showErrorMessage('Add flow name and try again.');
      return;
    }

    // add teamBalance to flow configList if config name is Airdrop
    const dataToUpload = {
      status,
      name: flowName,
      nodes,
      edges,
      config: configList.map((elem) => (elem.name === 'Airdrop' ? ({
        ...elem,
        team_balance: elem.source_of_nfts === 'users_wallet' ? -1 : teamBalance,
      }) : elem)),
      ...dates,
    };

    if (flowId) {
      changeFlow({
        id: flowId,
        payload: dataToUpload,
      });
    } else if (state && state.folder) {
      saveFlow({ ...dataToUpload, folders: [state.folder] });
    } else {
      saveFlow(dataToUpload);
    }
    setShowDialog(false);
  };

  const onResultReceive = useCallback((response) => {
    if (response.isLoading || response.isFetching) {
      setStartAndStopDateFlow(false);
    }
    if (response.isSuccess) {
      if (response.status === 'fulfilled') {
        trackFlowCreated(user, response.data);
      }
      if (
        (response.originalArgs.status === 'running' || response.originalArgs.payload?.status === 'running'
          || response.originalArgs.status === 'scheduled' || response.originalArgs.payload?.status === 'scheduled')
        && response.data.errors) {
        setShowDialog(true);
        dispatch(setErrors(response.data.errors));
        setShowErrorsModal(true);
      }
      if (
        (response.originalArgs.status === 'running' && !response.data.errors)
        || (response.originalArgs.payload?.status === 'running' && !response.data.errors)
        || (response.originalArgs.status === 'scheduled' && !response.data.errors)
        || (response.originalArgs.payload?.status === 'scheduled' && !response.data.errors)
        || response.originalArgs.status === 'draft' || response.originalArgs.payload?.status === 'draft') {
        navigate('/flows');
      }
      response.reset();
    }
    if (response.isError) {
      if (response.error.status === 400) {
        showErrorMessage(Object.keys(response.error.data).length !== 0 && 'The Flow with the same name already exists');
      } else {
        showErrorMessage(Object.keys(response.error.data).length !== 0 && 'Something went wrong');
      }
      setShowDialog(true);
      response.reset();
    }
  }, [dispatch, navigate]);

  const onAlertMouseEnter = useCallback((id) => {
    const tempNodes = reactFlowInstance.getNodes();
    tempNodes.forEach((node) => {
      node.selected = node.id === id;
    });
    setNodes(tempNodes);
  }, [reactFlowInstance, setNodes]);

  const onLabelEdit = useCallback(() => {
    const tempNodes = reactFlowInstance.getNodes();
    tempNodes.forEach((node) => {
      node.selected = false;
    });
    setNodes(tempNodes);
  }, [reactFlowInstance, setNodes]);

  const onActivationStop = () => {
    stopFlow(flowId);
  };

  const edgeTypes = useMemo(() => ({
    custom: CustomEdge,
    // eslint-disable-next-line react/no-unstable-nested-components
    random: (props) => <RandomSplitEdge onLabelEdit={onLabelEdit} props={props} />,
  }), [onLabelEdit]);

  const nodeTypes = useMemo(() => ({
    segmentsNode: SegmentsNode,
    // eslint-disable-next-line react/no-unstable-nested-components
    randomSplitNode: (props) => <RandomSplitNode onAlertMouseEnter={onAlertMouseEnter} props={props} />,
    waitUntilNode: WaitUntilNode,
    waitForNode: WaitForNode,
    airDropNode: AirDropNode,
    webhookNode: WebhookNode,
    webhookESNode: WebhookESNode,
    listNode: ListNode,
    transactionNode: TransactionNode,
    conditionNode: ConditionNode,
    emailNode: EmailNode,
    pushNode: PushNode,
    smsNode: SMSNode,
    twitterNode: TwitterNode,
    discordNode: DiscordNode,
    connectorNode: ConnectorNode,
    addToListNode: AddToListNode,
    XMTPNode,
    formNode: FormNode,
  }), [onAlertMouseEnter]);

  useEffect(() => {
    onResultReceive(result);
  }, [result, onResultReceive]);

  useEffect(() => {
    onResultReceive(updateResult);
  }, [updateResult, onResultReceive]);

  useEffect(() => {
    if (stopFlowResult.isSuccess) {
      setActivationStop(false);
      stopFlowResult.reset();
      navigate('/flows');
    }
  }, [navigate, stopFlowResult]);

  useEffect(() => {
    const [difference] = nodes.filter((elem) => !previousNodesState.includes(elem));
    if (difference) {
      const index = previousNodesState.findIndex((elem) => elem.id === difference.id);
      if (index !== -1) {
        if (difference.height !== previousNodesState[index].height) {
          const nodeIndex = nodes.findIndex((elem) => elem.id === difference.id);
          const newArr = Object.assign([], nodes);
          newArr[nodeIndex].position.y -= 30;
          if (difference.type === 'webhookESNode' && flow?.status) {
            newArr[nodeIndex].height = 120;
          }
          setNodes(newArr);
        }
      }
    }
    setPreviousNodesState(nodes);
  }, [nodes, previousNodesState, setNodes]);

  useEffect(() => {
    if (flow) {
      setLastId(Number(flow.nodes[flow.nodes.length - 1].id.split('_').slice(-1).join()) + 1);
      const newNodes = JSON.parse(JSON.stringify(flow.nodes));
      newNodes.forEach((elem) => {
        elem.data.onRemove = onRemove;
      });
      const newEdges = JSON.parse(JSON.stringify(flow.edges));
      newEdges.forEach((elem) => {
        elem.data.onEdgeRemove = onEdgeRemove;
        if (elem.sourceHandle.includes('randomSplitNode_output')) {
          elem.data.onLabelChange = onLabelChange;
          elem.data.unselectEdge = unselectEdge;
        }
        if (flow.status === 'running' || flow.status === 'scheduled' || flow.status === 'stopped') {
          delete elem.label;
          elem.data.isLoading = true;
        }
      });
      setNodes(newNodes);
      setEdges(newEdges);
      dispatch(setFlowStatus(flow.status));
      dispatch(setFlowName(flow.name));
      setTitleValue(flow.name);
      dispatch(onSettingsChange(flow.config));
    }
  }, [dispatch, flow, onEdgeRemove, onRemove, setEdges, setNodes]);

  useEffect(() => () => dispatch(clearFlowState()), [dispatch]);

  useEffect(() => {
    const alertUser = (e) => {
      if (showDialog) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [showDialog]);

  useEffect(() => () => {
    if (!isFlowLoading && !isFlowFetching) {
      mainContainer[0].style.padding = '0 4em';
    }
  }, [mainContainer, isFlowLoading, isFlowFetching]);

  if (isStatsError) {
    showErrorMessage('Something went wrong, please refresh the page');
  }

  useEffect(() => {
    if (flowStats && Object.keys(flowStats).length) {
      const tempEdges = Object.assign(edges, []);
      Object.keys(flowStats).forEach((elem) => {
        const edgesWithTarget = tempEdges.filter((edge) => edge.target === elem);
        if (edgesWithTarget.length) {
          tempEdges.forEach((edge) => {
            if (edge.target === elem) {
              if (edgesWithTarget.length >= 2) {
                edge.data.stat = flowStats[edge.source];
              } else {
                edge.data.stat = flowStats[elem] / edgesWithTarget.length;
              }
            }
          });
        }
      });
      setEdges(tempEdges);
    }
  }, [flowStats]);

  const onErrorsCancel = () => {
    cancelNavigation();
    setShowErrorsModal(false);
  }

  return (
    isFlowLoading || isFlowFetching || isLoading || isFetching || isStatsLoading || isStatsFetching
      ? <Loader />
      : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {`${flowName} Flows - Absolute Labs Platform`}
            </title>
          </Helmet>
          <div className={styles.wrapper}>
            {updateResult.isLoading || updateResult.isFetching || result.isLoading || result.isFetching
              ? <RoundSpinner position="position-absolute" theme="light" />
              : null}
            {showErrorsModal && <ErrorsModal
              message={`Cannot activate "${flowName}" flow because of the issue. `}
              errors={flowErrors}
              onCancel={onErrorsCancel}
            />}
            {!isKeyAdded
              && <EmailModal onCancel={() => dispatch(setSendGridApiKey(true))} showDialog={setShowDialog} />}
            {showPrompt && (
              <ConfirmModal
                onSubmit={() => {
                  confirmNavigation();
                  if (nodes.length && nodes.some((elem) => elem.type === 'conditionNode')) {
                    dispatch(clearState());
                  }
                }}
                onCancel={cancelNavigation}
                buttonName="Proceed"
                title="Unsaved values"
                description="All unsaved values will not be saved! Are you sure?"
              />
            )}
            {showAirDropModal && (
              <ConfirmModal
                title="Airdrop is not available"
                description="Your account is not allowed to launch a Flow performing airdrops.
                 Please communicate with your CSM for details."
                buttonName="Got It"
                onSubmit={() => setShowAirDropModal(false)}
              />
            )}
            {XMPTKey && (
              <ConfirmModal
                title="XMTP block is not ready to be used"
                description="If you want to use XMTP block, you need to first connect the wallet in the settings."
                link={(
                  <a
                    className={`${styles.xmtp_link} text-decoration-underline`}
                    href="https://docs.absolutelabs.io/features/flows/channels/xmtp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn how to connect wallets with Absolute Labs
                  </a>
                )}
                buttonName="Go to settings"
                onSubmit={() => {
                  dispatch(setXMTPKey(false));
                  navigate('/settings');
                }}
                onCancel={() => dispatch(setXMTPKey(false))}
              />
            )}
            {activationStop && (
              <ConfirmModal
                title="Confirm stop"
                description={`Are you sure you want to stop “${flow.name}” flow?
                The flow status will be immediately changed to Stopped and you won't be able to activate it again`}
                buttonName="Stop"
                onSubmit={onActivationStop}
                onCancel={() => setActivationStop(false)}
                loading={stopFlowResult.isLoading || stopFlowResult.isFetching}
              />
            )}
            {startAndStopDateFlow && (
              <StartStopDateFlowModal
                onCancel={() => setStartAndStopDateFlow(false)}
                onSubmit={onSubmit}
              />
            )}
            <div className={styles.dndflow}>
              <NodeSettings />
              <ReactFlowProvider>
                <div className={styles.reactflow_wrapper}>
                  {flow?.status !== 'running' && flow?.status !== 'stopped' && flow?.status !== 'scheduled'
                    ? <ToolBox />
                    : null}
                  <div className="w-100 h-100">
                    <div
                      className={`
                        d-flex gap-2
                        align-items-center
                        justify-content-between
                        mt-5 me-3
                        ${flow?.status === 'running' || flow?.status === 'scheduled'
                          || flow?.status === 'stopped' ? 'mx-3' : ''}`}
                      ref={reactFlowWrapper}
                    >
                      <div>

                        <EditableTitle
                          defaultTitle="Add the flow name"
                          titleValue={titleValue}
                          setTitleValue={setTitleValue}
                          onSave={() => dispatch(setFlowName(titleValue))}
                          edit={
                            flow?.status !== 'running' && flow?.status !== 'scheduled' && flow?.status !== 'stopped'
                          }
                        />
                        {flow?.status && flow?.status !== 'draft'
                        && <EditableFoldersList itemId={flow?.id} itemType="flows" />}
                      </div>
                      {flow?.status ? (
                        <div className="d-flex flex-column align-items-end">
                          {flow.status === 'running' || flow.status === 'scheduled'
                            ? (
                              <div className="d-flex flex-column">
                                {flow?.start_at ? (
                                  <div className="d-flex align-items-center align-self-end">
                                    <span className={`${styles.status} me-2`}>
                                      Start date:
                                    </span>
                                    <span className={`${styles.status_info} ms-1`}>
                                      {format(new Date(flow?.start_at), 'MMMM dd, yyyy, p OOOO')}
                                    </span>
                                  </div>
                                ) : null}
                                {flow?.end_at ? (
                                  <div className="d-flex align-items-center align-self-end">
                                    <span className={`${styles.status} me-2`}>
                                      End date:
                                    </span>
                                    <span className={`${styles.status_info} ms-1`}>
                                      {format(new Date(flow?.end_at), 'MMMM dd, yyyy, p OOOO')}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          <div className="d-flex align-items-center">
                            <span className={`${styles.status} me-2`}>
                              Status:
                            </span>
                            {flow.status === 'running' || flow.status === 'scheduled'
                              ? <BlinkingDot />
                              : null}
                            <span className={`${styles.status_info} ms-1`}>
                              {flow.status}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      nodeTypes={nodeTypes}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      defaultMarkerColor="#90a0b7"
                      defaultZoom={1}
                      minZoom={nodes.length ? 0.2 : 1}
                      maxZoom={nodes.length ? 2 : 1}
                      snapGrid={initialSnapGrid}
                      snapToGrid
                      onConnect={onConnect}
                      onInit={setReactFlowInstance}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      onNodeDragStop={onNodeDragStop}
                      edgeTypes={edgeTypes}
                      deleteKeyCode={initialDeleteKeyCode}
                      nodesDraggable={flow?.status !== 'running'
                        && flow?.status !== 'scheduled' && flow?.status !== 'stopped'}
                      nodesConnectable={flow?.status !== 'running'
                        && flow?.status !== 'scheduled' && flow?.status !== 'stopped'}
                    />
                  </div>
                </div>
              </ReactFlowProvider>
            </div>
          </div>
          <div className={`${styles.nav} d-flex justify-content-end align-items-center`}>
            <button
              type="button"
              className="outline-button"
              onClick={() => navigate('/flows')}
            >
              Cancel
            </button>
            {flow?.status !== 'running' && flow?.status !== 'stopped' && flow?.status !== 'scheduled'
              ? (
                <div className={styles.nav_save}>
                  <button
                    type="submit"
                    className="outline-blue-button"
                    disabled={nodes.length === 0}
                    onClick={() => onSubmit('draft')}
                  >
                    Save as Draft
                  </button>
                  <button
                    type="submit"
                    className="regular-button"
                    disabled={nodes.length === 0}
                    onClick={() => {
                      if (!flowName) {
                        showErrorMessage('Add flow name and try again.');
                      } else if (!airDropPermission.air_drop
                        && nodes.map((elem) => elem.type).includes('airDropNode')) {
                        setShowAirDropModal(true);
                      } else {
                        setStartAndStopDateFlow(true);
                      }
                    }}
                  >
                    Save & Activate Flow
                  </button>
                </div>
              )
              : flow?.status !== 'stopped'
                ? (
                  <button
                    type="submit"
                    className="regular-button"
                    onClick={() => setActivationStop(true)}
                  >
                    Stop flow
                  </button>
                )
                : null}
          </div>
        </>
      )
  );
};

export default CreateFlow;
