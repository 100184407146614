import Calendar from '../../assets/icons/calendar.svg?url';
import Language from '../../assets/icons/language.svg?url';
import Wallet from '../../assets/icons/wallet.svg?url';
import Scroll from '../../assets/icons/scroll.svg?url';
import Country from '../../assets/icons/country.svg?url';
import Time from '../../assets/icons/time.svg?url';
import URL from '../../assets/icons/url.svg?url';
import URLCrossed from '../../assets/icons/url_crossed.svg?url';
import Segments from '../../assets/icons/segment_black.svg?url';
import {
  countriesOptions,
  languagesOptions,
} from '../../utils/webSDK';
import { audiencesApi } from '../../api/audiences';
import { apiTypes } from '../../utils/segments/constants';

export const conditionsConfig = [
  {
    id: 'dates',
    label: 'Dates',
    icon: Calendar,
    inputs: [
      { type: "date", label: "startDate", displayedLabel: 'Start date' },
      { type: "date", label: "endDate", displayedLabel: 'End date' },
    ]
  },
  {
    id: 'language',
    label: 'Language',
    icon: Language,
    inputs: [
      { type: "dropdown", label: "requirements", data: [{
          value: 'required',
          label: 'Required languages'
        },
        {
          value: 'excluded',
          label: 'Excluded languages'
        }] 
      },
      { type: "multiSelect", label: "languages", data: languagesOptions },
    ]
  },
  {
    id: 'wallet',
    label: 'Wallet detected',
    icon: Wallet,
    inputs: [
      { type: "dropdown", label: "wallet", data: [{
          value: "mustHave",
          label: "Visitor must have connected a wallet"
        },
        {
          value: "mustNotHave",
          label: "Visitor must not have connected a wallet"
        }] 
      }
    ]
  },
  {
    id: 'scroll',
    label: 'Scroll level',
    icon: Scroll,
    inputs: [
      { type: "textWithValue", label: "level", textBefore: "User must have scrolled at least", textAfter: "% of the page", max: 100 }
    ]
  },
  {
    id: 'country',
    label: 'Country',
    icon: Country,
    inputs: [
      { type: "dropdown", label: "requirements", data: [{
          value: 'required',
          label: 'Required countries'
        },
        {
          value: 'excluded',
          label: 'Excluded countries'
        }] 
      },
      { type: "multiSelect", label: "countries", data: countriesOptions },
    ]
  },
  {
    id: 'time',
    label: 'Time spent',
    icon: Time,
    inputs: [
      { type: "textWithValue", label: "timeSpent", textBefore: "User must have spent at least", textAfter: "seconds on the page" }
    ]
  },
  {
    id: 'urlReq',
    label: 'URL requirements',
    icon: URL,
    inputs: [
      {
        type: 'dropdownAndInputList',
        label: 'urlRequirements',
        fields: [
          { type: "dropdown", label: "requirements", data: [{
              value: 'contains',
              label: 'URL contains'
            },{
              value: 'starts',
              label: 'URL starts with'
            },
            {
              value: 'ends',
              label: 'URL ends with'
            }] 
          },
          {
            type: "text",
            label: "url",
            placeholder: "Enter URL"
          },
        ]
      }
    ]
  },
  {
    id: 'urlExc',
    label: 'URL Exclusions',
    icon: URLCrossed,
    inputs: [
      {
        type: 'dropdownAndInputList',
        label: 'urlRequirements',
        fields: [
          { type: "dropdown", label: "requirements", data: [{
              value: 'contains',
              label: 'URL contains'
            },{
              value: 'starts',
              label: 'URL starts with'
            },
            {
              value: 'ends',
              label: 'URL ends with'
            }] 
          },
          {
            type: "text",
            label: "url",
            placeholder: "Enter URL"
          },
        ]
      }
    ]
  },
  {
    id: 'segments',
    label: 'Segments',
    icon: Segments,
    inputs: [
      { 
        type: "labeledAsyncMultiSelect", 
        dataType: 'segments',
        label: "inSegments", 
        displayedLabel: 'Visitor must be in segments', 
      },
      { 
        type: "labeledAsyncMultiSelect", 
        dataType: 'segments',
        label: "notInSegments", 
        displayedLabel: 'Visitor must not be in segments', 
      }
    ]
  },
];

export const getDefaultForCondition = (id, label) => {
  if (id === 'dates' && label === 'startDate') return new Date();
  if (id === 'dates' && label === 'endDate') {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date;
  };
  if (id === 'language' && label === 'requirements') {
    return { value: 'required', label: 'Required languages' };
  };
  if (id === 'language' && label === 'languages') {
    return [];
  };
  if (id === 'country' && label === 'requirements') {
    return { value: 'required', label: 'Required countries' };
  };
  if (id === 'country' && label === 'countries') {
    return [];
  };
  if (id === 'wallet' && label === 'wallet') {
    return { value: 'mustHave', label: 'Visitor must have connected a wallet' };
  };
  if (id === 'scroll' && label === 'level') {
    return 30;
  };
  if (id === 'time' && label === 'timeSpent') {
    return 10;
  };
  if (id === 'urlReq' && label === 'urlRequirements') {
    return [
      { 
        condition: {
          value: 'contains',
          label: 'URL contains'
        }, 
        text: '' 
      }
    ];
  };
  if (id === 'urlExc' && label === 'urlRequirements') {
    return [
      { 
        condition: {
          value: 'contains',
          label: 'URL contains'
        }, 
        text: '' 
      }
    ];
  };
  if (id === 'segments' && label === 'inSegments') {
    return [];
  };
  if (id === 'segments' && label === 'notInSegments') {
    return [];
  };
};


