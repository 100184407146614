import React, { useState } from 'react'
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedConditions, setTitle as setStoredTitle } from '../../store/reducers/campaign';
import EditableTitle from '../../components/base/EditableTitle';
import Footer from './Footer';
import Selection from './Selection';
import styles from './FormCampaigns.module.scss'
import ConditionButtons from './ConditionButtons';
import AdvancedSettings from './AdvancedSettings';
import Conditions from './Conditions';

const FormCampaigns = () => {
  const dispatch = useDispatch();
  const conditions = useSelector(getSelectedConditions);
  const { state } = useLocation();
  const sourceFormId = state?.form;
  const sourceWebsiteId = state?.website;
  const [title, setTitle] = useState('');

  const mainContainer = document.getElementsByClassName('main');
  if (mainContainer !== null && mainContainer.length !== 0) {
    mainContainer[0].style.padding = 0;
    mainContainer[0].style.setProperty('margin-bottom', '0', 'important');
  }

  const onSubmit = (statusToSave) => {
    console.log("submit", statusToSave);
  }

  const onCancel = () => {
    console.log("cancel");
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Web Campaign - Absolute Labs Platform
        </title>
      </Helmet>
      <div className={styles.configuration_wrapper}>
        <EditableTitle
          defaultTitle="Add campaign name"
          titleValue={title}
          setTitleValue={setTitle}
          onSave={() => dispatch(setStoredTitle(title))}
          triggerNotification={false}
        />
        <div className={styles.configuration}>
          <div className={styles.title}>
            Configuration
          </div>
          <div className={styles.horizontal_separator} />
          <div className={styles.content_wrapper}>
            <div className={styles.content}>
              <Selection sourceFormId={sourceFormId} sourceWebsiteId={sourceWebsiteId} />
              {conditions && conditions.length > 0 
              ? 
                <Conditions conditions={conditions} />
              : 
                <span className={styles.description}>Select one or more conditions below, or leave empty to show the form to all visitors</span>
              }
              <ConditionButtons />
              <AdvancedSettings />
            </div>
          </div>
        </div>
      </div>
      <Footer onSubmit={onSubmit} onCancel={onCancel} />
    </>
  )
}

export default FormCampaigns
