import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import { debounce } from '../../../../../utils/debounce';
import { placeholderStyle } from '../../../../AddSegment/Condition/Condition.constants';
import { FormatGroupLabel, MultiValueLabel, MultiValueRemove } from '../../../../AddSegment/components/labels';
import styles from './LabeledAsyncMultiSelect.module.scss';
import { getUrl, parseData, getLabelComponent } from '../../../helper';

const LabeledAsyncMultiSelect = ({ conditionId, label, displayedLabel, value, type }) => {
  const dispatch = useDispatch();
    
  const handleChange = (newValue) => {
    dispatch(setSelectedValue({ conditionId, label, value: newValue }))
  }

  const getData = useCallback(async (val) => {
    const handler = getUrl(type, val);
    const apiData = await dispatch(
      handler.url.initiate(
        typeof handler.value === 'string' ? handler.value : { ...handler.value },
        { forceRefetch: 10 },
      ),
    );
    return parseData(apiData, type);
  }, [dispatch]);

  const loadOptions = useCallback(debounce((val) => getData(val), 1000), [getData]);
  const getLabel = useCallback((val) => getLabelComponent(type, val), []);

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{displayedLabel}</span>
        <AsyncSelect
          styles={placeholderStyle}
          maxMenuHeight={194}
          components={
            {
            // eslint-disable-next-line react/no-unstable-nested-components
              MultiValueRemove: (props) => <MultiValueRemove props={props} show="label" />,
              // eslint-disable-next-line react/no-unstable-nested-components
              MultiValueLabel: (props) => <MultiValueLabel props={props} show="label" />,
            }
          }
          className="w-100"
          formatGroupLabel={FormatGroupLabel}
          value={value}
          loadOptions={loadOptions}
          getOptionLabel={(val) => getLabel(val)}
          isSearchable
          cacheOptions={false}
          onChange={handleChange}
          defaultOptions
          isShowValue
          isMulti
        />
    </div>
  )
}

export default LabeledAsyncMultiSelect
