import React from 'react';
import Select from 'react-select';
import ReactSwitch from 'react-switch';
import { placeholderStyle } from '../../../AddSegment/Condition/Condition.constants';
import styles from './TextWithDropdownAndToggle.module.scss';

const TextWithDropdownAndToggle = ({ textBefore, textBetween, isChecked, button, data, onChange }) => {
  const handleChange = (type, newValue) => {
    if (onChange) {
      onChange(type, newValue);
    }
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.text}>{textBefore}</span>
      <Select
        styles={placeholderStyle}
        maxMenuHeight={194}
        className="w-100"
        value={button}
        options={data}
        hideSelectedOptions
        onChange={(val) => handleChange('button', val)}
        isShowValue
      />
      <span className={styles.text}>{textBetween}</span>
      <ReactSwitch
        checked={isChecked}
        onChange={(val) => handleChange('click', val)}
        checkedIcon={false}
        uncheckedIcon={false}
        height={16}
        width={28}
        handleDiameter={14}
        onColor="#109cf1"
        offColor="#adb5bd"
      />
    </div>
  )
}

export default TextWithDropdownAndToggle
