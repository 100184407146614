import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Link, useParams, useSearchParams, useLocation,
} from 'react-router-dom';
import SingleFormTitle from '../../../components/ui/TitleSection/Titles/SingleFormTitle';
import FormActivityGraph from './FormActivityGraph';
import { setTabPages } from '../../../store/reducers/forms';
import uniqueId from '../../../utils/uniqueId';
import ResponsesTable from './FormAnalyticsTabs/Responses';
import { formsApi } from '../../../api/forms';
import ShareFormModal from '../Modals/ShareFormModal';
import { STATIC_FIELDS } from '../Constants';
import Details from './FormAnalyticsTabs/Details';

const FormAnalytics = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [shareFormModal, setShareFormModal] = useState(false);
  const [shareUrl, setShareUrl] = useState('');

  const { formId } = useParams();

  const {
    currentData: form,
    isLoading: isFormLoading,
    isFetching: isFormFetching,
  } = formsApi.useGetFormByIdQuery(
    {
      id: formId,
    },
    { skip: !formId || isLoaded },
  );

  const isStaticForm = (form?.field_settings?.find((page) => page.pageName === 'main-form')?.fields.every(
    (field) => STATIC_FIELDS.includes(field.name.toLowerCase()),
  )) || false;

  const selectedTab = searchParams.get('tab') || (isStaticForm ? 'Detailed Stats' : 'Responses'); 

  const {
    data: formDataOverview,
    isLoading: isFormDataOverviewLoading,
    isFetching: isFormDataOverviewFetching,
  } = formsApi.useResponsesTopBlockQuery(
    form?.url,
    { skip: !formId || isLoaded || !form },
  );

  useEffect(() => () => {
    setIsLoaded(false);
    dispatch(setTabPages([]));
  }, [dispatch]);

  const handleShareClick = (fullUrl) => {
    setShareUrl(fullUrl);
    setShareFormModal(true);
  };

  const pages = useMemo(() => [
    ...(!isStaticForm ? [{
      label: 'Responses',
      component: <ResponsesTable
        url={form?.url}
        isFormLoading={isFormLoading || isFormFetching
          || isFormDataOverviewLoading || isFormDataOverviewFetching}
        form={form}
        responses={formDataOverview?.response_count}
      />,
      id: 1,
    }] : []),
    { 
      label: 'Detailed Stats', 
      component: <Details 
        url={form?.url} 
        isFormLoading={isFormLoading || isFormFetching}
        isStaticForm={isStaticForm}
      />
      , id: 2 
    },
  ], [form, isFormLoading, isFormFetching, isFormDataOverviewLoading, isFormDataOverviewFetching, formDataOverview]);

  return (
    <div>
      <SingleFormTitle
        isFormLoading={isFormLoading || isFormFetching}
        formData={form}
        onShareClick={handleShareClick}
      />
      {shareFormModal && (
        <ShareFormModal
          onCancel={() => setShareFormModal(false)}
          url={shareUrl}
        />
      )}
      <div className="asset-section mt-3 pb-4">
        <FormActivityGraph
          url={form?.url}
          formDataOverview={formDataOverview}
          isFormLoading={isFormLoading || isFormFetching}
          isDataOverviewLoading={isFormDataOverviewLoading || isFormDataOverviewFetching}
          isStaticForm={isStaticForm}
        />
      </div>
      <div className="asset-section m-o mt-4">
        <div className="title-gap asset-navigation">
          <ul className="nav nav-pills gap-2">
            {pages.map((elem) => (
              <li
                role="presentation"
                className="nav-item"
                key={uniqueId('segment-analytics-tab')}
                onClick={() => {
                  setSearchParams({
                    tab: elem.label,
                    scroll: '',
                  });
                }}
              >
                <Link
                  className={`nav-link ${
                    elem.label === selectedTab ? 'active' : ''
                  }`}
                  to={`${pathname}${search}`}
                >
                  {elem.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown-divider w-100" />
        {pages.find((page) => page.label === selectedTab)?.component}
      </div>
    </div>
  );
};

export default FormAnalytics;
