import React, {useMemo} from 'react';
import Loader from '../../../../components/base/Loader';
import NoData from '../../../../components/base/NoData';
import HorizontalValueBarChart from '../../../../components/ui/HorizontalBarChart/ValueBased';
import LogoDisplay from '../../../../components/ui/modals/SearchModal/LogoDisplay';


const ECommerceContainer = ({
  title, isLoading, data
}) => {
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  const containerContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="p-5">
          <Loader />
        </div>
      );
    }
    let parsedData;
    if (data) {
      const filteredData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));
      if (title === 'Conversion Likelihood' || title === 'Web 3 Adoption per Visitor Type') {
        parsedData = Object.keys(filteredData).map((key) => ({
          name: key,
          value: filteredData[key] ? filteredData[key].toFixed(1) : null
        }))
      } else if (title === 'Web 3 Adoption by Country') {
        parsedData = data.map((elem) => ({
          name: regionNamesInEnglish.of(elem.country),
          value: elem ? elem.adoption.toFixed(1) : null,
          logo: (
            <span className="d-flex align-items-center gap-2 w-50">
                <LogoDisplay
                  logo={`/trackedWebsite/countryFlags/${elem.country.toLowerCase()}.svg`}
                  size="15px"
                  asset="address"
                  position="static"
                />
                <span className="text-capitalize">
                  {regionNamesInEnglish.of(elem.country)}
                </span>
              </span>
          ),
        }))
      }
      parsedData = parsedData.sort(({value:a}, {value:b}) => b-a)
    }

    return parsedData && parsedData.length
      ? (
        <HorizontalValueBarChart
          title={title}
          data={parsedData}
        />
      )
      : (
        <div className="d-flex justify-content-center align-items-center">
          <NoData />
        </div>
      )
  }, [data, isLoading, title]);

  return (
    <div className={'col-sm-12 col-md-6 col-lg-4'}>
      {containerContent}
    </div>
  );
}

export default ECommerceContainer