import { formsApi } from "../../api/forms";
import { audiencesApi } from "../../api/audiences";
import { webSDKApi } from "../../api/webSDK";
import { apiTypes } from "./constants";
import { CustomListDataLabel } from "../AddSegment/components/labels";

export const getUrl = (type, val) => {
  if (!type || !apiTypes.includes(type)) return null;

  let url;
  let value;
  switch (type) {
    case 'forms':
      url = formsApi.endpoints.getForms;
      value = {
        search: val || '', limit: 100,
      };
      break;
    case 'websites':
      url = webSDKApi.endpoints.getWebSDK;
      value = { search: val || '' };
      break;
    case 'segments':
      url = audiencesApi.endpoints.getFlowsSegments;
      value = val;
      break;
  }

  return { url, value };
}

export const parseData = (apiData, type) => {
  if (!type || !apiTypes.includes(type)) return;

  let returnData = null;
  switch (type) {
    case 'websites':
      if (apiData.status === 'fulfilled') {
        returnData = apiData.data.map((item) => ({
          value: item.site_id,
          label: item.website_name,
          id: item.id,
        }));
      }
      break;
    case 'forms':
      if (apiData.status === 'fulfilled') {
        returnData = apiData.data.results.map((item) => ({
          value: item.id,
          label: item.name,
          id: item.id,
        }));
      }
      break;
    case 'segments':
      if (apiData.status === 'fulfilled') {
        return apiData.data.results.filter((item) => item?.ready).map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }
      break;
  }

  return returnData;
};

export const parseSingleData = (rawData, type) => {
  if (!type || !apiTypes.includes(type)) return;

  let returnData = null;
  switch (type) {
    case 'websites':
      returnData = {
        value: rawData.site_id,
        label: rawData.website_name,
        id: rawData.id,
      };
      break;
    case 'forms':
      returnData = {
        value: rawData.id,
        label: rawData.name,
        id: rawData.id,
      };
      break;
  }

  return returnData;
};

export const getLabelComponent = (type, val) => {
  if (!type || !apiTypes.includes(type)) return null;

  switch (type) {
    case 'segments':
      return <CustomListDataLabel val={val} additional={false} />;
  }
}

export const parseButtons = (rawButtons) => {
  if (!rawButtons) return [];
  return rawButtons.map((button) => ({
    value: button.id,
    label: button.options.text
  }))
}
