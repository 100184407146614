import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { showSuccessMessage } from '../../../base/Notifications';
import Copy from '../../../../assets/icons/copy_outline.svg';
import { formsApi } from '../../../../api/forms';
import EditableFoldersList from '../../EditableFoldersList';
import styles from '../TitleSection.module.scss';
import { onSettingsChange } from '../../../../store/reducers/flows';
import { LoadingLines } from '../../modals/SearchModal/LoadingList';

const SingleFormTitle = ({ isFormLoading, formData, onShareClick }) => {
  const [data, setData] = useState({});
  const [url, setUrl] = useState('https://dev.wrm.link/');

  const showCreateFlowButton = formData?.field_settings?.find(
    (page) => page.pageName === 'main-form',
  )?.fields?.some(
    (field) => field.type === 'email' || field.type === 'connectWallet',
  ) || false;
  const [pauseForm, { isLoading: isPausing }] = formsApi.useCloseFormMutation();
  const [continueForm, { isLoading: isContinuing }] = formsApi.useContinueFormMutation();

  useEffect(() => {
    if (isFormLoading) {
      setData({});
    }
    if (window.location.hostname === 'dev.absolutelabs.app') {
      setUrl('https://dev.wrm.link/');
    } else if (window.location.hostname === 'localhost') {
      setUrl('http://localhost:4000/');
    } else if (window.location.hostname === 'staging.absolutelabs.app') {
      setUrl('https://staging.wrm.link/');
    } else {
      setUrl('https://wrm.link/');
    }
    if (formData) {
      setData(formData);
    }
  }, [isFormLoading, formData]);

  const changeStatus = () => {
    if (data.status === 'Active') {
      pauseForm(data.id);
      setData({ ...data, status: 'Closed' });
    } else {
      continueForm(data.id);
      setData({ ...data, status: 'Active' });
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createFlow = useCallback(() => {
    if (formData) {
      const formConfig = {
        id: formData.id,
        url: formData.url,
        name: formData.name,
        label: formData.name,
        value: formData.name,
        status: formData.status,
        creator: formData.creator,
        node_id: 'node_0',
        node_name: formData.name,
        created_at: formData.created_at,
        submissions_count: formData.submissions_count,
      };

      dispatch(onSettingsChange([formConfig]));
      navigate('/add-flow');
    }
  }, [formData, dispatch, navigate]);

  const fullUrl = url + data.url;

  const handleCreateCampaign = () => {
    navigate('/form/campaign', { state: { form: formData.id } });
  }

  return (
    <div className={styles.formWrapper}>
      <div className="d-flex flex-column">
        {isFormLoading ? (
          <div className="w-25">
            <LoadingLines align="start" />
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-wrap gap-2">
              <div className="d-flex flex-column gap-2">
                <div className={`${styles.title} d-flex gap-2 align-items-center`}>
                  {data.name}
                  <div className={`
                    ${styles.active_pill}
                    ${styles[`${data?.status?.toLowerCase()}_pill`]}
                    px-3 d-flex align-items-center`}
                  >
                    {data.status === 'Completed' || data.status === 'Paused' ? 'Closed' : data.status}
                  </div>
                </div>
                <EditableFoldersList itemId={data.id} itemType="forms" />
                <div className={styles.author}>
                  Created by
                  {' '}
                  {data.creator}
                  {' '}
                  on
                  {' '}
                  {data.created_at ? format(new Date(data.created_at), 'MMM dd, yyyy') : ''}
                </div>
                { data?.url?.length > 0
                  ? (
                    <div className="d-flex gap-2 align-items-center">
                      {fullUrl.includes('localhost') ? fullUrl : fullUrl.replace('https://', '')}
                      <Copy
                        className="cursor-pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(fullUrl);
                          showSuccessMessage('The URL was copied to your clipboard');
                        }}
                      />
                      <button
                        type="button"
                        className={`${styles.share} regular-button text-nowrap`}
                        onClick={() => onShareClick(fullUrl)}
                      >
                        Share
                      </button>
                    </div>
                  )
                  : ''}
              </div>
            </div>
            <div className="d-flex gap-3">
              {showCreateFlowButton ? (
                <button
                  type="button"
                  className="outline-blue-button"
                  onClick={() => createFlow()}
                >
                  Create a Flow
                </button>
              ) : null}
              <button
                type="button"
                className="outline-blue-button text-nowrap"
                disabled={isPausing || isContinuing}
                onClick={() => changeStatus()}
              >
                {data.status === 'Active' ? 'Close Form' : 'Reopen Form'}
              </button>
              {data.status === 'Active' && (
                <button
                  type="button"
                  className="regular-button text-nowrap"
                  onClick={() => handleCreateCampaign()}
                >
                  Create a Web Campaign
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleFormTitle;
