import React from 'react';
import {
  Routes, Route, Navigate,
} from 'react-router-dom';

import Wrapper from './Wrapper';
import Dashboard from '../../pages/Dashboard';
import Homepage from '../../pages/Homepage';
import AssetDetails from '../../pages/AssetDetails';
import NFTDetails from '../../pages/NFTDetails';
import MyTeam from '../../pages/MyTeam';
import Segments from '../../pages/Segments';
import AddSegment from '../../pages/AddSegment';
import Flows from '../../pages/Flows';
import Forms from '../../pages/Forms';
import CreateFlow from '../../pages/Flows/CreateFlow';
import CreateForm from '../../pages/Forms/CreateForm';
import FormsAnalytics from '../../pages/Forms/FormsAnalytics';
import NotFound from '../../pages/NotFound';
import AcceptedInvitation from '../../pages/AcceptedInvitation';
import GoogleCloudSignUp from '../../pages/GoogleCloudSignUp';
import SegmentDetails from '../../pages/SegmentDetails';
import Settings from '../../pages/Settings';
import ProfileDetails from '../../pages/ProfileDetails';
import CustomList from '../../pages/CustomList';
import CustomListDetails from '../../pages/CustomListDetails';
import RejectedInvitation from '../../pages/RejectedInvitation';
import CSVUpload from '../../pages/CSVUpload';
import WebSDK from '../../pages/WebSDK';
import Account from '../../pages/Account';
import CustomInsights from '../../pages/CustomInsights';
import FormCampaigns from '../../pages/FormCampaigns';

const Layout = () => (
  <Routes>
    <Route path="/" element={<Wrapper />}>
      <Route path="/" element={<Navigate replace to="dashboard" />} />
      <Route path="search" element={<Homepage />} />
      <Route index path="dashboard" element={<Dashboard />} />
      <Route path="coins/:name" element={<AssetDetails />} />
      <Route path="nfts/:network/:name" element={<NFTDetails />} />
      <Route path="profile/:name" element={<ProfileDetails />} />
      <Route path="my-team" element={<MyTeam />} />
      <Route path="audiences" element={<Segments />} />
      <Route path="segment/:id" element={<SegmentDetails />} />
      <Route path="add-segment" element={<AddSegment />} />
      <Route path="add-segment/:id" element={<AddSegment />} />
      <Route path="flows" element={<Flows />} />
      <Route path="forms" element={<Forms />} />
      <Route path="custom-insights" element={<CustomInsights />} />
      <Route path="add-custom-list" element={<CustomList />} />
      <Route path="csv-upload" element={<CSVUpload />} />
      <Route path="add-custom-list/:id" element={<CustomList />} />
      <Route path="custom-list/:id" element={<CustomListDetails />} />
      <Route path="add-flow" element={<CreateFlow />} />
      <Route path="add-flow/:flowId" element={<CreateFlow />} />
      <Route path="add-form" element={<CreateForm />} />
      <Route path="add-form/:formId" element={<CreateForm />} />
      <Route path="form-analytics/:formId" element={<FormsAnalytics />} />
      <Route path="tracked-website/:id" element={<WebSDK />} />
      <Route path="accepted" element={<AcceptedInvitation />} />
      <Route path="gcp-signup" element={<GoogleCloudSignUp />} />
      <Route path="rejected" element={<RejectedInvitation />} />
      <Route path="settings" element={<Settings />} />
      <Route path="account" element={<Account />} />
      <Route path="form/campaign" element={<FormCampaigns />} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default Layout;
