import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import { placeholderStyle } from '../../../../AddSegment/Condition/Condition.constants';
import styles from './DropdownInput.module.scss';

const DropdownInput = ({ 
  conditionId,
  label,
  index,
  condition, 
  conditionData,
  text, 
  allValues
}) => {
  const dispatch = useDispatch();
  const [displayedText, setDisplayedText] = useState(text);
  
  const handleConditionChange = (newValue) => {
    dispatch(setSelectedValue({ conditionId, label, value: allValues.map((item, i) => 
      i === index ? { ...item, condition: newValue } : item
    ), }))
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const updatedValues = allValues.map((item, i) =>
        i === index ? { ...item, text: displayedText } : item
      );
  
      if (JSON.stringify(updatedValues) !== JSON.stringify(allValues)) {
        dispatch(setSelectedValue({ conditionId, label, value: updatedValues }));
      }
    }, 400);
  
    return () => {
      clearTimeout(timer);
    };
  }, [displayedText, allValues, conditionId, label, index]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.select_wrapper}>
        <Select
          styles={placeholderStyle}
          maxMenuHeight={194}
          className="w-100"
          value={condition}
          options={conditionData}
          hideSelectedOptions
          onChange={handleConditionChange}
          isShowValue
        />
      </div>
      <input 
        className={styles.text} 
        type="text"
        value={displayedText}
        onChange={(e) => setDisplayedText(e.target.value)}
      />
    </div>
  )
}

export default DropdownInput
