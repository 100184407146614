import React from 'react';
import SelectCtrl from 'react-select';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from '../../../../../store/reducers/campaign';
import { placeholderStyle } from '../../../../AddSegment/Condition/Condition.constants';
import styles from './Select.module.scss';

const Select = ({ conditionId, label, value, data }) => {
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    if (newValue.value !== value.value) {
      dispatch(setSelectedValue({ conditionId, label, value: newValue }))
    }
  }

  return (
    <div className={styles.wrapper}>
      <SelectCtrl
        styles={placeholderStyle}
        maxMenuHeight={194}
        className="w-100"
        value={value}
        options={data}
        hideSelectedOptions
        onChange={handleChange}
        isShowValue
      />
    </div>
  )
}

export default Select
